import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import green_tick from '../assets/images/green_tick.png';
import yellow_error from '../assets/images/yellow_error.png';
import green_loading from '../assets/images/loader-green.gif';
import MuiAlert from '@mui/material/Alert';
import { API_DOMAIN, eventStatusMap, Whatsapp_DOMAIN } from '../components/constants';
import { Button, Input, Modal, Row, } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Badge, Space, Select } from 'antd';
import useSnackbar from '../components/Snackbar/UseSnackbar';
import SaveIcon from '@mui/icons-material/Save';
import RemoveIcon from '@mui/icons-material/Remove';
const { Title, Paragraph, Text, Link } = Typography;

// The MUI alert definition
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Whatsapp() {
    const { SuccessSnackbar, ErrorSnackbar, InfoSnackbar, WarningSnackbar, ToastContainer } = useSnackbar();
    const [openModel, setOpenModal] = useState(false);
    const handleModalOpen = () => {
        setOpenModal(true)
    }
    const handleModalClose = () => {
        setOpenModal(false)
    }
    const waStatusCheck = async () => {
        axios.post(API_DOMAIN + "/whatsapp/status", { api_secret: "uzdpart" }).then((res) => {
            if (res.data.success) {
                setWaStatus(res.data.data.status);
                switch (res.data.data.status.status) {
                    case eventStatusMap.qr:
                        document.getElementById('wa_status_img').src = res.data.data.status.qr;
                        document.getElementById('qr_caption').innerHTML = "Scan Now!!"
                        document.getElementById('qr_button').innerHTML = "Tries remaining : " + (5 - res.data.data.status.attempts)
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                    case eventStatusMap.logged_in:
                        document.getElementById('wa_status_img').src = green_tick
                        document.getElementById('qr_button').innerHTML = "Logout"
                        document.getElementById('qr_caption').innerHTML = "Logged in successfully"
                        break;
                    case eventStatusMap.logged_out:
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        document.getElementById('qr_caption').innerHTML = "Logged-out, Request new QR"
                        document.getElementById('wa_status_img').src = yellow_error;
                        break;
                    case eventStatusMap.qr_timeout:
                        handleOpenError("QR scanning time-out, Please try again!")
                        document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                        document.getElementById('wa_status_img').src = yellow_error
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        break;
                    case eventStatusMap.loading_screen: case eventStatusMap.init_whatsapp:
                        document.getElementById('qr_caption').innerHTML = "Checking WA-Bot status"
                        document.getElementById('wa_status_img').src = green_loading
                        document.getElementById('qr_button').innerHTML = "Checking..."
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                    case eventStatusMap.session_restoring: case eventStatusMap.logging_in:
                        if (res.data.data.status.attempts < 5) {
                            document.getElementById('qr_caption').innerHTML = "Logging in..."
                            document.getElementById('wa_status_img').src = res.data.data.status.qr ? res.data.data.status.qr : green_loading;
                            document.getElementById('qr_button').innerHTML = "processing..."
                            setTimeout(() => { waStatusCheck(); }, 3000);
                        }
                        else {
                            handleOpenError("QR scanning time-out, Please try again!")
                            document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                            document.getElementById('wa_status_img').src = yellow_error
                            document.getElementById('qr_button').innerHTML = "Request new QR"
                        }
                        break;
                    default:
                        document.getElementById('qr_caption').innerHTML = "Click below to request new QR"
                        document.getElementById('wa_status_img').src = yellow_error
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        setTimeout(() => { waStatusCheck(); }, 3000);
                        break;
                }
            } else {
                document.getElementById('qr_caption').innerHTML = "QR Scanning Timeout"
                document.getElementById('wa_status_img').src = yellow_error
            }
        }).catch((err) => { handleOpenError(err.message) });
    }

    //client form_data
    const [clientIdx, setClientIdx] = useState(null);
    const [clientCode, setClientCode] = useState(971);
    const [clientNumber, setClientNumber] = useState(null);




    const [waStatus, setWaStatus] = useState([]);
    const [todayQueries, setTodayQueries] = useState(0);
    const [AllTimeQueries, setAllTimeQueries] = useState(0);
    const [WaImg, setWaImg] = useState(green_loading);

    //success snackbar stuff
    const [openSuccessAlert, setSuccessAlert] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const handleOpenSuccess = (reason) => {
        setSuccessMessage(reason);
        setSuccessAlert(true);
    };
    const handleCloseSuccess = () => {
        setSuccessAlert(false);
    };

    //Error snackbar stuff
    const [openErrorAlert, setErrorAlert] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const handleOpenError = (reason) => {
        setErrorMessage(reason);
        setErrorAlert(true);
    };
    const handleCloseError = () => {
        setErrorAlert(false);
    };

    const handleQrRequest = () => {
        switch (document.getElementById('qr_button').innerHTML) {
            case "Request new QR":
                axios.post(API_DOMAIN + "/whatsapp/init_qr", { api_secret: "uzdpart" }).then((res) => {
                    if (res.data.success) {
                        document.getElementById('qr_button').innerHTML = "Requesting new QR..."
                        document.getElementById('wa_status_img').src = green_loading
                        document.getElementById('qr_caption').innerHTML = "Fetching QR..."
                        waStatusCheck();
                    }
                });
                break;
            case "Logout":
                axios.post(API_DOMAIN + "/whatsapp/logout", { api_secret: "uzdpart" }).then((res) => {
                    if (res.data.success) {
                        handleOpenSuccess("Logged-out successfully")
                        document.getElementById('qr_button').innerHTML = "Request new QR"
                        document.getElementById('qr_caption').innerHTML = "Logged-out successfully, Request new QR"
                        document.getElementById('wa_status_img').src = yellow_error;
                    }
                    else {
                        openErrorAlert('Failed to logout!\nTry again later.')
                    }
                });
                break;

            default:
                break;
        }
    };

    const [clientStatus, setClientStatus] = useState([]);
    const get_all_client_status = async () => {
        const res = await axios.get(Whatsapp_DOMAIN + '/get_all_client_info');
        if (res.data.success) {
            setClientStatus(res.data.data[0].data);
            SuccessSnackbar("Fetched Bot Information")
        }
        else {
            ErrorSnackbar("Unable to fetch Bot Information")
        }
    }

    const [userDataset, setUserDataset] = useState([]);
    const reload_users = async () => {
        var res = await axios.get(API_DOMAIN + '/auth/get_all_users');
        if (res.data.success) {
            var formattedUserData = res.data.data.map(element => {
                return {
                    value: element.id,
                    label: element.name
                }
            })
            setUserDataset(formattedUserData);
        }
        else {
            ErrorSnackbar("Users data fetching failed");
        }
    }
    const handleUsersChange = (e, clientIdx) => {
        const updatedClientStatus = clientStatus.map((client) => {
            if (client.clientIdx === clientIdx) {
                return {
                    ...client,
                    clientUsers: e,
                };
            }
            return client;
        });

        setClientStatus(updatedClientStatus);
    };

    const update_clientstatus = async () => {
        var res = await axios.post(Whatsapp_DOMAIN + "/post_client_info", { data: clientStatus });
        if (res.data.success) {
            SuccessSnackbar(`Updated Client Info successfully`);
        }
        else {
            ErrorSnackbar(`Failed to update client Info`)
        }
    }
    useEffect(() => {
        const head = {
            headers: {
                accessToken: localStorage.getItem("accessToken")
            },
            api_secret: "uzdpart"
        }
        get_all_client_status();
        reload_users();
    }, []);


    return (
        <>
            <div>
                <div className='flex justify-between'>
                    <Title level={2}>.
                        WhatsApp Bot Management
                    </Title>
                    <Button
                        icon={<AddIcon />}
                        shape="round"
                        type="primary"
                        onClick={handleModalOpen}
                    >
                        New Client
                    </Button>
                </div>
                <div className='mt-2 flex'>
                    {clientStatus.map((element, index) => (
                        <div
                            key={index}
                            className='border rounded drop-shadow p-1 ml-3'
                            style={{ minWidth: "250px", maxWidth: "300px" }}
                        >
                            <div>
                                <div className='flex justify-center'>
                                    <Badge
                                        count={index + 1}
                                        style={{
                                            backgroundColor: '#52c41a',
                                        }}
                                    />
                                </div>
                                <div className='pl-1'>
                                    <Text>
                                        Client ID :&nbsp;<Badge
                                            count={element.clientIdx}
                                            style={{
                                                backgroundColor: '#5cabff',
                                                fontSize: "13px",
                                                fontWeight: "600"
                                            }}
                                        />
                                    </Text>
                                    <br />
                                    <Text>
                                        Whatsapp Number :&nbsp;<Badge
                                            overflowCount={999999999999}
                                            count={element.clientNumber}
                                            style={{
                                                backgroundColor: '#5cabff',
                                                fontSize: "13px",
                                                fontWeight: "600"
                                            }}
                                        />
                                    </Text>
                                    <br />
                                    <div className='border rounded p-1 mt-3'>
                                        <Text>
                                            Alloted Users :&nbsp;
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Space style={{ width: '100% !important', }}>
                                                    <Space.Compact style={{ width: '100% !important', }}>
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            showSearch
                                                            optionFilterProp="label"
                                                            style={{ width: '100% !important', }}
                                                            filterOption={true}
                                                            placeholder="Select Users"
                                                            value={element.clientUsers}
                                                            onChange={(e) => { handleUsersChange(e, element.clientIdx); }}
                                                            options={userDataset}
                                                        />
                                                    </Space.Compact>
                                                </Space>
                                                <Button style={{ width: "28px", fontSize: "10px", alignSelf: "baseline" }} size='small' color="#4287f5s" variant="solid" onClick={update_clientstatus}>
                                                    <SaveIcon size="small"></SaveIcon>
                                                </Button>
                                            </div>
                                        </Text>
                                    </div>
                                </div>
                                <div className='rounded border m-3' style={{ width: "250px" }}>
                                    <div className='flex justify-center'>
                                        <Text>
                                            Login Status
                                        </Text>
                                    </div>
                                    <img id={'wa_status_img' + element.clientIdx} src={WaImg} alt='' />
                                </div>
                                <div className='flex justify-center p-1'>
                                    <Button id={`logout_btn` + element.clientIdx} variant='solid' color='danger'>
                                        Logout
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Modal
                title="Add New Client to Bot"
                centered
                loading={false}
                open={openModel}
                width={350}
                onCancel={handleModalClose}
                maskClosable={false}
                footer={
                    <div className='flex justify-end'>
                        <Button variant='solid' color='default'>
                            Create Client
                        </Button>
                    </div>
                }
            >
                <div className='drop-shadow border rounded'>
                    <div className='p-2'>
                        <Row>
                            <Input
                                allowClear
                                style={{ width: '100%', }}
                                placeholder="Enter client name/identifier"
                                maxLength={40}
                                value={clientIdx}
                                onChange={(e) => {
                                    setClientIdx(e.target.value.toLowerCase().replaceAll(" ", "_"))
                                }}
                            >
                            </Input>
                        </Row>
                        <Row className='pt-2 flex justify-between align-middle'>
                            <Input
                                allowClear
                                style={{ maxWidth: '25%', }}
                                placeholder="Country code"
                                maxLength={3}
                                value={clientCode}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value))
                                        setClientCode(e.target.value.replaceAll(" ", "").replaceAll("+", ""))
                                }}
                            >
                            </Input>
                            <div style={{ fontSize: "14px", fontWeight: "7                                                                                   00" }}>-</div>
                            <Input
                                allowClear
                                style={{ maxWidth: '65%', }}
                                placeholder="Enter client Phone Number"
                                maxLength={10}
                                value={clientNumber}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value))
                                        setClientNumber(e.target.value.replaceAll(" ", "").replaceAll("+", ""))
                                }} 
                            >
                            </Input>
                        </Row>
                    </div>
                </div>
            </Modal>




            <ToastContainer />
        </>
    );

}

export default Whatsapp;