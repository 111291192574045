import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Snackbar from '@mui/material/Snackbar';
import { API_DOMAIN } from '../components/constants';
import { Button } from 'react-bootstrap';
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExcelLogo from "@mui/icons-material/ExcelLogo";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

function QuerysetV3() {

  // useEffect(() => {

  //   const head = {
  //     headers: {
  //       accessToken: localStorage.getItem("accessToken")
  //     }
  //   }

  //   // axios.get(API_DOMAIN + "/auth", head).then((res) => {
  //   // });

  //   // axios.get(API_DOMAIN + "/auth/approve", head).then((res) => {
  //   // });

  // }, []);
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <>
      <div className='border rounded p-2 flex justify-between bg-primary-hover'>
        <div className='flex justify-start border p-1 rounded'>
          <input
            value={searchTerm}
            className='ml-1'
            id="search_field"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          ></input>
          <Button className='ml-2'><SearchIcon /></Button>
          <Button className='ml-5'><PictureAsPdfIcon /> <FileDownloadIcon /></Button>
          <Button className='ml-2'><ExcelLogo /> <FileDownloadIcon /></Button>
        </div>
        <div className='flex justify-end'>
        </div>
      </div>
    </>
  );

}

export default QuerysetV3;