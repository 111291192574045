import { useLocation } from 'react-router-dom';

const GetTitle = () => {
  const location = useLocation();
  const path = location.pathname;

  switch (path) {
    case '/':
      return 'Home';
    case '/login':
      return 'Login';
    case '/users':
      return 'Users';
    case '/queryset':
      return 'Queryset';
    case '/querysetv2':
      return 'Queryset V2';
    case '/whatsapp':
      return 'WhatsApp Bot Status';
    case '/suppliers':
      return 'Suppliers List Management';
    case '/hrm':
      return 'Users List Management';
    case '/querysetv3':
      return 'Queryset V3';
    case '/test':
      return 'My Little Playground';
    default:
      return 'Not Found';
  }
};

export default GetTitle;
