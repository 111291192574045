import { Divider } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
const moment = require('moment');

const ChatList = ({ chats, onSelectChat, selectedChatId, loadMoreChats }) => {
    const divRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleScroll = () => {
        const bottom =
            divRef.current.scrollHeight - divRef.current.scrollTop ===
            divRef.current.clientHeight;
        if (bottom && !isLoading) {
            setIsLoading(true);
            loadMoreChats(chats[chats.length - 1].id); // Function to load more chats
        }
    };

    const calculate_status_chip = (status, chat) => {
        switch (status) {
            case 0:
                return (<div style={{ borderRadius: "5px", color: "#730026", padding: "3px", backgroundColor: "#ff0a5c" }}>Unassigned</div>)
            case 1:
                return (<div style={{ borderRadius: "5px", color: "rgb(47, 84, 57)", padding: "3px", backgroundColor: "rgb(226, 245, 212)" }}>Open</div>)
            case 2:
                return (<div style={{ borderRadius: "5px", color: "rgb(39, 78, 94)", backgroundColor: "rgb(207, 240, 253)", padding: "3px" }}>Solved</div>)
            case 3:
                return (<div style={{ borderRadius: "5px", color: "rgb(86, 64, 3)", backgroundColor: "rgb(252, 238, 193)", padding: "3px" }}>Pending</div>)
            case 4:
                return (<div style={{ borderRadius: "5px", color: "rgb(129, 53, 12)", backgroundColor: "rgb(254, 233, 216)", padding: "3px" }}>Expired</div>)
            case 6:
                return (<div style={{ borderRadius: "5px", color: "rgb(40, 65, 65)", padding: "3px", backgroundColor: "rgb(184, 228, 228)" }}>Broadcast</div>)
            default:
                alert(`LET ABHILASH KNOW ABOUT THIS ALERT BOX RIGHT NOW!`)
                alert(JSON.stringify(chat));
                break;
        }
    };
    useEffect(() => {
        const currentDiv = divRef.current;
        if (currentDiv) {
            currentDiv.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (currentDiv) {
                currentDiv.removeEventListener("scroll", handleScroll);
            }
        };
    }, [chats, isLoading]);

    useEffect(() => {
        if (isLoading) {
            // Simulate API delay for loading more chats
            setTimeout(() => setIsLoading(false), 1000);
        }
    }, [isLoading]);

    return (
        <div className="chat-list" ref={divRef} style={{ height: "400px", overflowY: "scroll" }}>
            {chats.map((chat) => (
                <div key={chat.id}>
                    <div
                        className="chat-item"
                        onClick={() => onSelectChat(chat.id)}
                        style={{
                            border: selectedChatId === chat.id ? "1px solid blue" : "none",
                        }}
                    >
                        <div style={{ fontWeight: "600", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {chat.name.length > 20 ? chat.name.substring(0, 20) + "..." : chat.name}
                            </div>
                            <div style={{ fontWeight: "normal", fontSize: "10px", display: "flex", justifyContent: "flex-end" }}>
                                {chat?.ticket?.assignedFullName?.length > 20 ? chat.ticket?.assignedFullName?.substring(0, 20) + "..." : chat?.ticket?.assignedFullName || ""}
                            </div>
                        </div>

                        <div style={{ fontSize: "12px" }}>
                            {chat.text.length > 30 ? chat.text.substring(0, 30) + "..." : chat.text}
                        </div>
                        <div style={{ fontSize: "10px", display: "flex", justifyContent: "space-between" }}>
                            <div>
                                {calculate_status_chip(chat.ticket.status, chat)}
                            </div>
                            <div>
                                {/*This will check if the date is before today and show the date also otherwise time only*/}
                                {moment(parseInt(chat.lastUpdated * 1000)).startOf('day').isBefore(moment().startOf('day')) ? moment(parseInt(chat.lastUpdated * 1000)).format('DD-MM-YYYY hh:mm:ss A') : moment(parseInt(chat.lastUpdated * 1000)).format('hh:mm:ss A')}
                            </div>
                        </div>
                    </div>

                    {/* Divider outside the chat-item div, but still within the same parent div */}
                    <Divider
                        style={{
                            backgroundColor: "black",
                            height: "2px",
                            width: "100%",
                            borderRadius: "2px",
                        }}
                    />
                </div>
            ))}

            {isLoading && <div>Loading more chats...</div>}
        </div>
    );
};

export default ChatList;
